
import config from './config'
import imgsComp from './components/imgsComp.vue'
import swiperComp from './components/swiperComp.vue'
import videoComp from './components/videoComp.vue'
import footerBtn from './components/footerBtn.vue'
import headerBar from '@/components/header/index.vue'
// import { onMounted, reactive, toRefs, ref } from 'vue'

export default{
  name: 'LeafletsHorizontal',
	setup(){
    let {
      bannerimgs: { horizontal: bannerimgs },
      detailImgs: { horizontal: detailImgs },
      swiperImgs: { horizontal: swiperImgs },
      otherImgs: { horizontal: otherImgs },
      pageTitle,
      tips
    } = config
		return {
      bannerimgs,
      detailImgs,
      swiperImgs,
      otherImgs,
      tips,
      pageTitle
    }
	},
  components: {
    imgsComp,
    swiperComp,
    videoComp,
    footerBtn,
    headerBar
  }
}
