<template>
  <div class="horizontal">
    <headerBar :isshow="true" :title=pageTitle :isBigBar='false'></headerBar>
    <div class="bannerImgs"><imgsComp :imgs=bannerimgs></imgsComp></div>
    <div class="video-wrap">
      <videoComp :isVertical = 'false'></videoComp>
    </div>
    <div class="detail">
      <imgsComp :imgs=detailImgs></imgsComp>
      <div class="swiper-content">
        <div class="swiper-content-left">
          <swiperComp :imgs=swiperImgs :isVertical = 'false'></swiperComp>
        </div>
        <div class="swiper-content-right">
          <div class='swiper-content-title'>使用方法</div>
          <p v-for="item in tips" :key=item>{{item}}</p>
        </div>
      </div>
      <imgsComp :imgs=otherImgs></imgsComp>
    </div>
    <footerBtn :isVertical = 'false'></footerBtn>
  </div>
</template>
<script lang="ts">
import config from './config'
import imgsComp from './components/imgsComp.vue'
import swiperComp from './components/swiperComp.vue'
import videoComp from './components/videoComp.vue'
import footerBtn from './components/footerBtn.vue'
import headerBar from '@/components/header/index.vue'
// import { onMounted, reactive, toRefs, ref } from 'vue'

export default{
  name: 'LeafletsHorizontal',
	setup(){
    let {
      bannerimgs: { horizontal: bannerimgs },
      detailImgs: { horizontal: detailImgs },
      swiperImgs: { horizontal: swiperImgs },
      otherImgs: { horizontal: otherImgs },
      pageTitle,
      tips
    } = config
		return {
      bannerimgs,
      detailImgs,
      swiperImgs,
      otherImgs,
      tips,
      pageTitle
    }
	},
  components: {
    imgsComp,
    swiperComp,
    videoComp,
    footerBtn,
    headerBar
  }
}
</script>

<style lang="scss" scoped>
.horizontal{
  padding-top: 6vw;
  padding-bottom: 3vw;
}
.bannerImgs{
  min-height: 34vw;
}
.detail{
}
.video-wrap{
  width: 100%;
  height: 41vw;
  padding: 2vw 13vw;
  background: #e2f4ec;
}
.swiper-content{
  display: flex;
  align-items: center;
  padding: 6vw 8vw;
  background-image: linear-gradient(180deg, #e2f4ec 0%, #ffffff 100%);
}
.swiper-content-title{
  font-family: PingFangSC-Semibold;
	font-size: 0.188rem;
	color: #179059;
}
.swiper-content-left{
  padding-top: 2vw;
}
.swiper-content-right{
  margin-left: 3vw;
  p{
    font-family: PingFangSC-Medium;
    font-size:  0.185rem;
    line-height: 4vw;
    color: #3e3e3e;
    margin-top: 1.1vw;
  }
}
</style>
